@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;500;700&display=swap');
body{
    font-family: 'Poppins', sans-serif;
    color: #1d1d24;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.71429;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    overflow-y: auto;
}
.spacer{
    padding-top: 40px;
    padding-bottom: 40px;
}
.brand {
    width: 90px;
    margin-right: 40px;
}
.text-red{
    color: #f9004d;
    font-weight: bold;
}

.navbar{
    color: rgba(255,255,255,.55);
}
.navbar-nav{
    margin-left: 10px;
    padding: 10px;
}
.navbar-nav .nav-link.active{
    border-bottom: 4px solid #fff;
}
.bg-home{
    background-image: url(./homeimg.png) !important;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: auto;
    height: auto;
    color: rgba(255,255,255,.55);
    padding: 20px 0px 60px;
}
.about{
    background-color:#eee;
}
.photo .card{
    background-color: #eeee;
}
span.welcome{
    letter-spacing: 4px;
}
